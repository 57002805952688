import { avalanche, ethereum, fantom, moonbeam, polygon } from "./testnet"

const exported = {
  ethereum,
  avalanche,
  fantom,
  moonbeam,
  polygon,
}
export default exported
